import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

window.addEventListener('load', function () {
  const CarouselContainer = document.querySelectorAll(
    '.slider-media-text__container'
  );
  if (0 < CarouselContainer.length) {
    new Swiper('.slider-media-text__container', {
      direction: 'horizontal',
      paginationClickable: true,
      mousewheel: {
        enabled: true,
        forceToAxis: true,
      },
      keyboard: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
        renderBullet: (index, className) => {
          if (index < 9) {
            return `<span class="${className}">0${index + 1}</span>`;
          }
          return `<span class="${className}">${index + 1}</span>`;
        },
      },
    });
  }
});
